import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { CountUpDirective } from './directives/count-up.directive';
import { AnimVisibilityDirective } from './directives/anim-visibility.directive';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeSevenComponent,
    CountUpDirective,
    AnimVisibilityDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
