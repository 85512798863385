import {Directive, ElementRef, HostListener, ViewChild} from '@angular/core';

@Directive({
  selector: '[appAnimVisibility]'
})
export class AnimVisibilityDirective {



    constructor(
        private el: ElementRef,
        ) {
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        const rect = this.el.nativeElement.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;
        const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);

        const threshold = 0.2; // how much % of the element is in view
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        console.log('true');
                        observer.disconnect();
                    }
                });
            },
            { threshold }
        );
        observer.observe(this.el.nativeElement);

    }

}
