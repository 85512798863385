<div class="main-navbar">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <div class="logo">
                <a routerLink="/home">
                    <img src="assets/img/logo.png" class="black-logo" alt="Logo" style="width: 160px">
                </a>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="#home" class="nav-link">
                            Home
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#about" class="nav-link">
                            About
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#services" class="nav-link">
                            Our Services
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#team" class="nav-link">
                            Team
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#contact" class="nav-link">
                            Contact
                        </a>
                    </li>
                </ul>
                <div class="others-option">
                    <div class="d-flex align-items-center">
                        <div class="option-item">
                            <a href="javascript:void(0)" class="sign-up">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<div id="home" class="main-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
<!--                    <h1>Mortgage Is A Great For You</h1>-->
                    <h1>Driving Software Solutions</h1>
                    <span class="sub-title">&nbsp;Reliable Sustainable Provider</span>
<!--                    <div class="banner-popup align-items-center">-->
<!--                        <a href="https://www.youtube.com/watch?v=Y5KCDWi7h9o" class="video-btn popup-youtube"><i class="bx bx-play"></i> <span>Intro With Company</span></a>-->
<!--                    </div>-->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-form-wrap">

                    <form>
                            <div class="single-footer-widget ps-3">
                                <h3>What do we do?</h3>

                                <ul class="quick-link">
                                    <li>
                                        <a href="#" target="_blank">Provide Software Solutions</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">Custom Software Systems Development</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">Systems Support & Maintanance</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">IT Consultancy</a>
                                    </li>
                                </ul>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="about-style-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image">
                    <img src="assets/img/more-home/about/about1.jpg" alt="image">

                    <div class="quote-content">
                        <p>“When you are born, you look like your parents. When you grow, you look like your decisions.”</p>
                        <span>Apostle Joshua Selman</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-style-content">
                    <span class="sub-title">About Our Company</span>
                    <h3>Who are we?</h3>
                    <p class="bold">Grapevyn Networks is a software development company that builds software solutions for
                        institutional and individual end-user clients.</p>
                    <p>The company’s extensive portfolio of tech solutions empowers clients across various sectors to leverage cutting-edge technologies, simplifying their work, enhancing their quality of life, and driving efficiency and effectiveness in their industries.</p>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="counter-box">
                                <h4>10+</h4>
                                <span>Years Of Experience</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="counter-box">
                                <h4>100+</h4>
                                <span>Active Users</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="services" class="core-service-area ptb-100 ">
    <div class="container">
        <div class="main-section-title">
            <span class="sub-title">What We Offer</span>
            <h2>Products And Service</h2>
        </div>


        <div class="core-service-list-tab">

        <div class="tab-content mb-5">
            <div class="tab-pane fade show active" id="easycoms" role="tabpanel">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="core-service-content">
                            <img src="assets/img/more-home/core-service/easycomz-logo.png" alt="image" class="mb-4" style="width: 150px">
                            <p>EasyComz is an advanced software solution mainly built for  Christian churches. The software helps churchs to leverage available technologies
                            inorder to store, track and store information as well as automate process.</p>
                            <ul class="list">
                                <li><i class='bx bx-chevrons-right'></i> Manage members</li>
                                <li><i class='bx bx-chevrons-right'></i> Send bulk announcements</li>
                                <li><i class='bx bx-chevrons-right'></i> Record Visitors</li>
                                <li><i class='bx bx-chevrons-right'></i> Manage and track pledges</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6">
                        <div class="core-service-image">
                            <img src="assets/img/more-home/core-service/poster-easycomz.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-content mb-5">
            <div class="tab-pane fade show active" id="piggery-expert" role="tabpanel">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-7 col-md-6">
                        <div class="core-service-content">
                            <img src="assets/img/more-home/core-service/piggery-expert-logo.png" alt="image" class="mb-4" style="width: 150px">
                            <p>A comprehensive solution to piggery farming. Piggery Expert helps with the necessary expertise to ensure productivity and profitability to piggery farmers.</p>
                            <ul class="list">
                                <li><i class='bx bx-chevrons-right'></i> Record keeping and information extraction</li>
                                <li><i class='bx bx-chevrons-right'></i> Daily activity prompts</li>
                                <li><i class='bx bx-chevrons-right'></i> Vaccination management</li>
                                <li><i class='bx bx-chevrons-right'></i> Feed managemnet</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6">
                        <div class="core-service-image">
                            <img src="assets/img/more-home/core-service/piggery-img.jpeg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>



            <div class="main-section-title">
                <span class="sub-title">Other Services</span>
            </div>

            <div class="core-service-list-tab">



            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="core-service-1-tab" data-bs-toggle="tab" href="#core-service-1" role="tab" aria-controls="core-service-1">Custom Software Development</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="core-service-2-tab" data-bs-toggle="tab" href="#core-service-2" role="tab" aria-controls="core-service-2">System Support, Maintenance</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="core-service-3-tab" data-bs-toggle="tab" href="#core-service-3" role="tab" aria-controls="core-service-3">IT Consultancy</a>
                </li>

            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="core-service-1" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-building-house"></i>
                                </div>
                                <h3>Custom Software Development</h3>
                                <p>Either its data storage, data processing or automation, with the domain expert we can tailor make software to cater for your specific requirements</p>
                                <ul class="list">
                                    <li><i class='bx bx-chevrons-right'></i> Web Applications</li>
                                    <li><i class='bx bx-chevrons-right'></i> Mobile Applications</li>
                                    <li><i class='bx bx-chevrons-right'></i> Desktop Applications</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/more-home/core-service/core-service-1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-2" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-dollar-circle"></i>
                                </div>
                                <h3>System Support, Maintenance</h3>
                                <p>For the systems that we develop, we provide full time support and maintenance as well as upgrades to ensure that all our solutions are working at their best.</p>
                                <ul class="list">
                                    <li><i class='bx bx-chevrons-right'></i> 24hr call assistance</li>
                                    <li><i class='bx bx-chevrons-right'></i> System Training</li>
                                    <li><i class='bx bx-chevrons-right'></i> System upgrades</li>
                                    <li><i class='bx bx-chevrons-right'></i> Maintenance procedures</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/more-home/core-service/core-service-2.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-3" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-home-circle"></i>
                                </div>
                                <h3>IT Consultancy</h3>
                                <p>Do you need advice on the best way to integrate software solutions in your process? We provide consultancy to guide you to make the right decisions</p>
                                <ul class="list">
                                    <li><i class='bx bx-chevrons-right'></i> Redesign processes incorporating software solutions</li>
                                    <li><i class='bx bx-chevrons-right'></i> Software testing and evaluation</li>
                                    <li><i class='bx bx-chevrons-right'></i> Software purchase advice</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/more-home/core-service/core-service-3.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</div>

<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <div class="footer-logo">
                        <a href="#">
                            <img src="assets/img/logo_black_white.jpg" alt="Logo" >
                        </a>
                        <p>Success is not only what you
                            are able to do for yourself but
                            the impact you make in other
                            people’s lives</p>
                        <ul class="footer-social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget ps-3">
                    <h3>Quick Links</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">About Us</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">What We Offer</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget ps-3">
                    <h3>Support</h3>

                    <ul class="quick-link">
                        <li>
                            <a href="#" target="_blank">Contact</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#" target="_blank">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+4498886660000">+263 000 000</a>
                        </li>
                        <li>
                            <i class='bx bx-message-square-detail'></i>
                            <a href="mailto:hello@levi.com">admin@grapevynnetworks.com</a>
                        </li>
                        <li>
                            <i class='bx bx-current-location' ></i>
                            3687 55 Crescent, Glen View,
                            Harare
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</footer>
